var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":!_vm.cvData || _vm.loading}},[_c('validation-observer',{ref:"update_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdate($event)}}},[_c('b-card',{attrs:{"title":"Personal Summary"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Professional Title","label-for":"professional_title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Professional Title"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"professional_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"professional_title","placeholder":"Ex: Lead Software Engineer","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.professional_title),callback:function ($$v) {_vm.$set(_vm.formData, "professional_title", $$v)},expression:"formData.professional_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Preferred Job Interests","label-for":"job_interests"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Preferred Job Interests"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"job_interests","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"job_interests","multiple":"","close-on-select":false,"loading":_vm.loading,"clearable":false,"reduce":function (val) { return val.value; },"options":_vm.jobCategoryOptions,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.formData.job_interests),callback:function ($$v) {_vm.$set(_vm.formData, "job_interests", $$v)},expression:"formData.job_interests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Professional Summary Or Objectives","label-for":"summary"}},[_c('validation-provider',{attrs:{"name":"Professional Summary Or Objectives","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"summary","rows":"6","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.summary),callback:function ($$v) {_vm.$set(_vm.formData, "summary", $$v)},expression:"formData.summary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Why You?","label-for":"why_you"}},[_c('validation-provider',{attrs:{"name":"Why You?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"why_you","rows":"6","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.why_you),callback:function ($$v) {_vm.$set(_vm.formData, "why_you", $$v)},expression:"formData.why_you"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{staticStyle:{"width":"150px"},attrs:{"block":"","type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Update ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }