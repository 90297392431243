<template>
  <b-overlay :show="!cvData || loading">
    <validation-observer ref="update_form" #default="{invalid}">
      <b-form ref="form" @submit.prevent="onUpdate">
        <b-card title="Personal Summary">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Professional Title"
                label-for="professional_title"
                class="mb-2"
              >
                <template #label>
                  <span>Professional Title<span style="color: tomato">*</span></span>
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="professional_title"
                  rules="required"
                >
                  <b-form-input
                    id="professional_title"
                    v-model="formData.professional_title"
                    placeholder="Ex: Lead Software Engineer"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <b-form-group label="Preferred Job Interests" label-for="job_interests">
                <template #label>
                  <span>Preferred Job Interests<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="job_interests" rules="required">
                  <v-select
                    id="job_interests"
                    v-model="formData.job_interests"
                    multiple
                    :close-on-select="false"
                    :loading="loading"
                    :clearable="false"
                    :reduce="val => val.value"
                    :options="jobCategoryOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Professional Summary Or Objectives"
                label-for="summary"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Professional Summary Or Objectives"
                  rules="required"
                >
                  <b-form-textarea
                    id="summary"
                    v-model="formData.summary"
                    rows="6"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <b-form-group
                label="Why You?"
                label-for="why_you"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Why You?"
                  rules="required"
                >
                  <b-form-textarea
                    id="why_you"
                    v-model="formData.why_you"
                    rows="6"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                block
                type="submit"
                variant="primary"
                style="width: 150px"
                :disabled="invalid"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { get } from "lodash";
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BOverlay, BForm, BFormGroup, BButton, BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormTextarea } from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,

    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BOverlay,
    BCardBody,
    BFormGroup,
    BCardHeader,
    BFormInput,
    BFormTextarea,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,

      cvData: null,
      
      formData: {
        professional_title: '',
        job_interests: '',
        summary: '',
        why_you: '',
      },

      // validations
      required
    }
  },
  watch: {
    cvData: {
      handler(update){
        if (update){
          const { professional_title, job_interests, summary, why_you } = update;
          this.formData = { professional_title, job_interests, summary, why_you }
        }
      }, 
      immediate: false, 
      deep: true
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch(){
      try {
        this.loading = true;
        
        const cvFetchReq = await this.useJwt().clientService.fetchCV();
        const { data } = cvFetchReq.data;
        this.cvData = data;
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onUpdate(){
      try {
        this.loading = true;
        
        const success = await this.$refs.update_form.validate();
        if (!success){
          return;
        }

        await this.useJwt().clientService.updateCV(this.cvData._id, this.formData);
        this.fetchCVScore()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `${this.$route.meta.pageTitle} updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
